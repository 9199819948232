<template>
	<div class="container">
		<div class="container-form">
			<div>
				<el-form @submit.native.prevent="onSubmit" :model="model" :rules="rules" ref="form">
					<div>
						<el-form-item prop="username">
							<el-input size="large" placeholder="Ingrese usuario" v-model="model.username" :prefix-icon="User" />
						</el-form-item>
						<el-form-item prop="password">
							<el-input size="large" placeholder="Ingrese contraseña" v-model="model.password" :prefix-icon="Lock" type="password" show-password />
						</el-form-item>
						<el-form-item prop="sucursal_id">
							<el-select placeholder="Seleccione sucursal" size="large" @blur="searchCajas" v-model="model.sucursal_id">
								<el-option v-for="(option, index) in sucursales.data" :key="index" :label="option" :value="Number(index)" />
							</el-select>
						</el-form-item>
						<el-form-item prop="caja_id">
							<el-select placeholder="Seleccione caja" size="large" :disabled="disabled" v-model="model.caja_id">
								<el-option v-for="(option, index) in filteredCajas.data" :key="option.id" :label="option.nombre" :value="option.id" />
							</el-select>
						</el-form-item>
					</div>
					<el-button type="primary" native-type="submit" round>Iniciar sesión</el-button>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, ref, watch, computed, onMounted } from 'vue';
import { User, Lock } from '@element-plus/icons-vue';
import { message, confirm } from '@/utils';

const model = reactive({
	username: '',
	password: '',
	sucursal_id: '',
	caja_id: '',
});

const filteredCajas = ref([]);
const sucursales = ref([]);

onMounted(async () => {
	const res = await fetch('/api/sucursal/list');
	sucursales.value = await res.json();
});

const searchCajas = async () => {
	const res = await fetch(`/api/caja/bySucursal/${model.sucursal_id}`);
	filteredCajas.value = await res.json();

	return filteredCajas.value;
};

/* refs */
const form = ref(null);
const show = ref(false);

const disabled = computed(() => {
	return !model.sucursal_id ? true : false;
});

/* submit */
const onSubmit = async (e) => {
	form.value.validate(async (valid) => {
		if (valid) {
			let res = await fetch('/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(model),
			});

			if (res.status == 200) {
				res = await res.json();

				window.location = '/';
			} else {
				res = await res.json();

				message('error', res.error);
			}
		} else {
			message('warning', 'Verifique los datos del formulario');
		}
	});
};

/* model */

/* rules */
const rules = reactive({
	username: {
		required: true,
		message: 'Ingrese usuario',
	},
	password: {
		required: true,
		message: 'Ingrese contraseña',
	},
	sucursal_id: {
		required: true,
		message: 'Ingrese sucursal',
	},
	caja_id: {
		required: true,
		message: 'Ingrese caja',
	},
});
</script>
